/* 

GENERIC

*/


.login-panel{max-width: 450px;}

.item .description,.item .header	{clear:both; text-align:left;}
.ui.statistic>.value~.label, .ui.statistics .statistic>.value~.label {
    margin-top: 4px;
}
.ui.statistic>.value {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.ui.menu .item img.logo {
    margin-right: 1em;
}

.actions .ui.button {
	padding:.78571429em 0.8em .78571429em;
}

.actions .ui.labeled.icon.button,.actions .ui.labeled.icon.buttons .button {
    position: relative;
    padding-left: 2.5em!important;
    padding-right: 1em!important;
}

.login-form .warning	{
	text-align:left;
}

table.sortable .hidden-text span{
	
	display:none;
}

.responsive-table {overflow-x:auto; display:block;}

.ui.grid .accordion{
	padding-left:0px!important;
	padding-right:0px!important;
}

.dropdown.fluid .ui.input{
	
	padding-right: 1.7em!important;

}
.dropdown.fluid .input>i.icon

{
	right:20px;
}
.sidebar-widget{ background: #ffebd0;}
.ui.widget {

    background: #ffebd0;
    border-radius: 30px;
    margin: 20px 0 25px;
    padding: 20px;
    overflow: hidden;
	    padding: 10px 20px;
	 background: rgb(255,235,208);
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 320px) {
	
	.ui.card.boxed {width:100%;}
	.ui.grid>.column.zero {padding:0px!important;}
	

}

.logo-pad {
    padding-right: 90px
}