.ui.sidebar {
    width:89px !important;
}

.ui.visible.left.sidebar~.pusher{
    transform: translate3d(89px,0,0) !important;
}

.ui.left.sidebar~.pusher{
    padding: 25px 100px 0px 25px;
}