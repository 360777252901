.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h2{
  font-weight: normal;
  font-family: "Lato", "Arial", sans-serif ;

}

p{
  font-weight: normal;
  font-size: 11pt;
}

.Sidebar-fullheight {
  min-height: 100vh
}

.Dealers p{
  margin: 5px 0 0 0;
}

.Dealers{
  position: relative;
  display: flex;
  flex-direction: row;
  width: 98%;
  flex-wrap: wrap;
  margin: 1%;
}

.Dealership {
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: #b2b2b2;
  width: 15%;
  margin: 0.75%;
  background-color: #f1f1f1;
  color: #4d4d4d;
  text-align: left;
  box-sizing: border-box;

}

.hidden {
  visibility: hidden;
  position: absolute;
}

p.error {
  color: #ED5565;
  font-size: 14px;
  float: left;
}

.pulsion-toast {
  float: right;
}

.picture{
  width: 100%;
  background-color: #808080;
  height: 150px;
  object-fit: contain;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: #2d2d2d;
}

.Details{
  padding: 0 8% 6% 8%;
}


@media screen and (max-width: 1500px){
  .Dealership{
    width: 18%;
  }
}

@media screen and (max-width: 1400px){
  .Dealership{
    width: 23%;
  }
}

@media screen and (max-width: 1200px){
  .Dealership{
    width: 31%;
    margin: 0.875%;
  }
}

@media screen and (max-width: 900px){
  .Dealership{
    width: 45.5%;
    margin: 2%;
  }
}

@media screen and (max-width: 600px){
  .Dealership{
    width: 98.5%;
    margin: 3%;
  }
}

.login-form{
  padding-top: 10%;
}

.login-panel{
  max-width: 450px;
  padding-top: 10%;
}

.form-panel {
  padding-bottom: 14px;
}

.logo-pad {
  padding-right: 90px
}

/*
  PASSWORD STRENGTH INDICATOR
*/
.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;
}
.strength-meter:before, .strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 6px 0;
  position: absolute;
  width: calc(20% + 6px);
  z-index: 10;
}
.strength-meter:before {
  left: calc(20% - 3px);
}
.strength-meter:after {
  right: calc(20% - 3px);
}
.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out, background 0.25s;
}
.strength-meter-fill[data-strength="0"] {
  width: 20%;
  background: darkred;
}
.strength-meter-fill[data-strength="1"] {
  width: 40%;
  background: orangered;
}
.strength-meter-fill[data-strength="2"] {
  width: 60%;
  background: orange;
}
.strength-meter-fill[data-strength="3"] {
  width: 80%;
  background: yellowgreen;
}
.strength-meter-fill[data-strength="4"] {
  width: 100%;
  background: green;
}

/*
  LOGBOOK
*/
.ui.spacer	{margin:1em;}

.ui.fluid.accordion, .ui.fluid.accordion .accordion {
  padding-bottom: 0.3em;
  border-radius: 0px;
}
.text-center {text-align:center;}

.column.zero	{padding-right:0px!important; padding-left:0px!important;}

/* job card */
.ui.card{
  margin:0px;
  outline: 2px solid #00a99d;outline-offset: -3px;
}
.ui.card.boxed{border-radius:0px;margin-top:0px;}
.month {
  font-weight:600;
  text-transform:uppercase;
  padding-top:.5em;
  padding-bottom:.5em;}
.zero .ui[class*="top attached"].segment {border-radius:0px;}
.description {padding-top:.5em;padding-bottom:.5em;}
.ui.corner.label {
  margin-top: 1px;
  margin-left: 1px;
  padding-top: 5px;
  inline-size: 20px;
  border-top-color:#00a99d;
}
.ui.label.floated {
  background-color: transparent;
}
.ui.attached.segment.color {
  outline: 2px solid #0071bc;
  outline-offset: -4px;
}

.ui.dimmer {
  position: relative;
  padding-top: 50px;
}

/* pink */
.label.Yearly {color:white!important;border-top-color:#d4145a!important;}
/* orange */
.label.Monthly {color:white!important;border-top-color:#f15a24!important;}
/* blue */
.label.Weekly {color:white!important;border-top-color:#0071bc!important;}
/* purple */
.label.Daily {color:white!important;border-top-color:#714991!important;}

/* pink */
.card.Yearly {outline: 2px solid #d4145a;outline-offset: -3px;}
/* orange */
.card.Monthly {outline: 2px solid #f15a24;outline-offset: -3px;}
/* blue */
.card.Weekly {outline: 2px solid #0071bc;outline-offset: -3px;}
/* purple */
.card.Daily {outline: 2px solid #714991;outline-offset: -3px;}

.extra.content[data-tooltip] {
  position: static;
}
.circular[data-tooltip]:after {
  z-index: 2;
}

.ui.corner.label{
  background-color: rgba(255, 255, 255, 0) !important;
}