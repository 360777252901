body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 20px;
  border-color: #cfcfd0;
  -webkit-border-image: none;
          border-image: none;
  border-style: solid;
  border-width: 1px;
 border-top: 4px solid #1ab394;
}

.actions {
  margin:0px 0 10px 0;
  text-align:right;
}

/*Buttons*/
.btn {
  border-radius: 3px;
  margin-bottom:0px;
  padding: 6px 12px;
}

a.btn {
  text-decoration: none;
  background-color: #dddddd;
  color: #333333;
  border-top: 1px solid #dddddd;
  border-right: 1px solid #898989;
  border-bottom: 1px solid #898989;
  border-left: 1px solid #dddddd;
}

.btn-right{
  float: right;
}

.btn-primary {
  background-color: #1ab394;
  border-color: #1ab394;
  color: #FFFFFF;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.active:focus {
    background-color: #178b74;
    border-color: #178b74;
    color: #FFFFFF;
}

.btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
    background-color: #1fd1ad;
    border-color: #1fd1ad;
}

.btn-outline {
  -webkit-transition: all .5s;
  transition: all .5s;
  background:#1ab394;
  border:1px solid #1ab394;      
}

.btn-outline .fa:before{
  color:#fff;
  font-size:24px;
}

.btn,.inline-button {
 
}


.btn-danger {
  background-color: #ED5565;
  border-color: #ED5565;
  color: #FFFFFF;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger, .btn-danger:active:focus, .btn-danger:active:hover, .btn-danger.active:hover, .btn-danger.active:focus {
    background-color: #ac3f4a;
    border-color: #ac3f4a;
    color: #FFFFFF;
}

.btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
    background-image: none;
}

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger.active[disabled], fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active {
    background-color: #e77d88;
    border-color: #e77d88;
}

.action-btn{
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0px;
  display: inline-block;
  clear: none;
  float: none;
  color:#fff; 
  text-align: center;
  
}

.action-btn > i{
  margin-right: 0px !important;
}

i .action-btn span{
  text-align: center;
  padding-left: 3px;
}

.link-button {
  background:none!important;
  color:blue;
  border:none; 
  padding:0!important;
  font: inherit;
  border-bottom:1px solid blue; 
  cursor: pointer;
}

table td .action-btn{
  margin:2px!important;
}
.IssueSearch{
  padding-bottom: 1em
}
.search-date-delimiter{
  padding-top: 0.7em;
  text-align: center;
  font-weight: bold;
}
.full-width {
  width: 100%;
}

.date-picker{
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  padding: .67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
  -webkit-transition: box-shadow .1s ease,border-color .1s ease;
  transition: box-shadow .1s ease,border-color .1s ease;
  box-shadow: none;
}

/*pagination */

.pagination {
  text-align: center;
  display: block;
  margin: 10px auto;
  clear: both;
  padding: 0px;
  list-style-type: none;

}

.pagination li  {display:inline-block;}
.datatables-info {text-align: center;margin-top:10px;}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus
{
  background:#19aa8d;
  color:#fff;
}
.ui.centered.grid{
  margin-top:1em!important;
  margin-bottom:2em!important;
}

.ui.pagination.menu .active.item{
  background-color:#1ab394;
  color: #fff;
}

.form-heading h2 {
    float:left;
    text-decoration: none;
    color: #1ab394;
    font-style: inherit;
    font-weight: 500;
    line-height: 38px;
    margin-top:0px;
}

.form-heading  em {
    font-style:normal; 
    color:#1ab394;
}
.form-heading small{
  color:#1ab394;
  padding-left: 1em;
}   

.form-heading  span.label  {
    display:inline-block;
    float:none; 
    margin-left:5px;
    margin-right:5px;
    font-size:12px;
    padding: 7px 10px;
    top: -4px;
    position: relative;
}

.ui.sortable.table thead th{
  border-left: none !important;
}

.list-body{
  font-size: small;
  min-height: 300px;
  clear: both;
}

.site-list > .IssueSearch{
  margin-left: 2.4em;
}

.clearfix {
  clear: both;
}

/*comments*/
.border-top{
  border-top: solid 1px #cfcfd0;
  padding: 1em;
  margin: 5px;
}
.comment-header-left{
  float: left;
  width: 50%;
  font-weight: bold;
}
.comment-header-right{
  float: right;
  width: 50%;
  text-align: right;
}
.comment-header{
  height: 28px;
  padding: 5px;
}
.comment-content{
  padding-top: 10px;
  white-space: pre-wrap;
}

/*issue categories*/
.category-high{
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: red;
}
.category-medium{
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: orange;
}
.category-low{
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: green;
}

.category-open{
  text-align: center;
  background-color: white;
  border: 1px solid lightgrey;
  text-transform: uppercase;
}

.category-closed{
  text-align: center;
  background-color: lightgrey;
  text-transform: uppercase;
}
.category-onhold{
  text-align: center;
  background-color: gray;
  text-transform: uppercase;
}

.sqr-priority-high{
  text-transform: uppercase;
  text-align: center;
  color: white;
  background-color: red;
}
.sqr-priority-medium{
  text-transform: uppercase;
  text-align: center;
  color: white;
  background-color: orange;
}
.sqr-priority-low{
  text-transform: uppercase;
  text-align: center;
  color: white;
  background-color: green;
}

.categoy-inline > div > div{
  display: inline-block;
  white-space: nowrap;
  padding-left: 2em;
  padding-right: 2em;
}

/*issue details*/
.issue-details-header{
  padding-bottom: 0.5em;
}
.issue-cell{
  padding: 2em !important;
}
.issue-details-devider{
  border-left: solid 1px #cfcfd0
}

/*Logbook*/
.pointer {
  cursor: pointer;
}

.documentBody > div > p{
  margin-top: 0.5em;
  color: white;
}
.documentBody > div{
  padding: 2em;
  height: 600px;
  overflow-y: auto;
}
.documentBody .content{
  background: gray;
}

.document-menu{
  border-right: 1px #dededf solid;
}

.pdf-header{
  margin-bottom: 0.5em;
  text-align: center;
}
.pdf-header p{
  color: white;
}
.pdf-header > p > button {
    margin-left: 0.5em;
}
.pdf-body canvas{
  margin: auto;
}

.site-visit-container{
  padding: 2em;
  background: gray;
}
.site-visit-iframe{
  width: 100%;
  border: none;
  height: 600px;
  overflow-y: auto;
}

.site-visit-header{
  margin-bottom: 1em;
  text-align: center;
}

.site-visit-header p{
  color: white;
}

.site-visit-header > p > a {
  margin-left: 0.5em;
}



/* AK overrides */ 
.btn-outline {
    background: #ffffff!important;
    border: 1px solid #c2c2c5!important;
	font-size:14px;
	}
	
.btn-primary {
    background: #ffffff;
    border: 1px solid #c2c2c5;
    color: #1263ca;
}

.btn {
    border-radius: 3px;
    margin-bottom: 0;
    padding: 9px 10px;
	cursor:pointer;
	font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
	}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active{
	 color: #1263ca;
	 background: #f2f2f2!important;
	/*border-color:#1263ca!important;*/
	border-color:#c2c2c5!important;
}

.closed {
    background: #0dc70d;
    color: #fff;
    border: none;
}
.category {
    padding: 0px 4px;
    display: inline-block;
    min-width: 80px;
	text-align: center;
    text-transform: uppercase;
}

.open {
    background: #e61010;
    color: #fff;
    border: none;
}

.onhold {
    background: #ff6000;
    color: #fff;
    border: none;
}

.float-right{float:right;}
.pusher h1	{float:left;}
.link-button {
    color: #1b1c1d;
    text-decoration: underline;
    border-bottom: 0px;
}

/* borderless datagrid */

.list-body .ui.table{
	border-left:0px;
	border-right:0px;
}

/*IE 11 Fix for Semantic Modal*/
.ui.modal{
  position: relative!important;
  margin: 0px!important;
}
.ui.sidebar {
    width:89px !important;
}

.ui.visible.left.sidebar~.pusher{
    -webkit-transform: translate3d(89px,0,0) !important;
            transform: translate3d(89px,0,0) !important;
}

.ui.left.sidebar~.pusher{
    padding: 25px 100px 0px 25px;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

h2{
  font-weight: normal;
  font-family: "Lato", "Arial", sans-serif ;

}

p{
  font-weight: normal;
  font-size: 11pt;
}

.Sidebar-fullheight {
  min-height: 100vh
}

.Dealers p{
  margin: 5px 0 0 0;
}

.Dealers{
  position: relative;
  display: flex;
  flex-direction: row;
  width: 98%;
  flex-wrap: wrap;
  margin: 1%;
}

.Dealership {
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: #b2b2b2;
  width: 15%;
  margin: 0.75%;
  background-color: #f1f1f1;
  color: #4d4d4d;
  text-align: left;
  box-sizing: border-box;

}

.hidden {
  visibility: hidden;
  position: absolute;
}

p.error {
  color: #ED5565;
  font-size: 14px;
  float: left;
}

.pulsion-toast {
  float: right;
}

.picture{
  width: 100%;
  background-color: #808080;
  height: 150px;
  object-fit: contain;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: #2d2d2d;
}

.Details{
  padding: 0 8% 6% 8%;
}


@media screen and (max-width: 1500px){
  .Dealership{
    width: 18%;
  }
}

@media screen and (max-width: 1400px){
  .Dealership{
    width: 23%;
  }
}

@media screen and (max-width: 1200px){
  .Dealership{
    width: 31%;
    margin: 0.875%;
  }
}

@media screen and (max-width: 900px){
  .Dealership{
    width: 45.5%;
    margin: 2%;
  }
}

@media screen and (max-width: 600px){
  .Dealership{
    width: 98.5%;
    margin: 3%;
  }
}

.login-form{
  padding-top: 10%;
}

.login-panel{
  max-width: 450px;
  padding-top: 10%;
}

.form-panel {
  padding-bottom: 14px;
}

.logo-pad {
  padding-right: 90px
}

/*
  PASSWORD STRENGTH INDICATOR
*/
.strength-meter {
  position: relative;
  height: 3px;
  background: #ddd;
  margin: 7px 0;
  border-radius: 2px;
}
.strength-meter:before, .strength-meter:after {
  content: '';
  height: inherit;
  background: transparent;
  display: block;
  border-color: #fff;
  border-style: solid;
  border-width: 0 6px 0;
  position: absolute;
  width: calc(20% + 6px);
  z-index: 10;
}
.strength-meter:before {
  left: calc(20% - 3px);
}
.strength-meter:after {
  right: calc(20% - 3px);
}
.strength-meter-fill {
  background: transparent;
  height: inherit;
  position: absolute;
  width: 0;
  border-radius: inherit;
  -webkit-transition: width 0.5s ease-in-out, background 0.25s;
  transition: width 0.5s ease-in-out, background 0.25s;
}
.strength-meter-fill[data-strength="0"] {
  width: 20%;
  background: darkred;
}
.strength-meter-fill[data-strength="1"] {
  width: 40%;
  background: orangered;
}
.strength-meter-fill[data-strength="2"] {
  width: 60%;
  background: orange;
}
.strength-meter-fill[data-strength="3"] {
  width: 80%;
  background: yellowgreen;
}
.strength-meter-fill[data-strength="4"] {
  width: 100%;
  background: green;
}

/*
  LOGBOOK
*/
.ui.spacer	{margin:1em;}

.ui.fluid.accordion, .ui.fluid.accordion .accordion {
  padding-bottom: 0.3em;
  border-radius: 0px;
}
.text-center {text-align:center;}

.column.zero	{padding-right:0px!important; padding-left:0px!important;}

/* job card */
.ui.card{
  margin:0px;
  outline: 2px solid #00a99d;outline-offset: -3px;
}
.ui.card.boxed{border-radius:0px;margin-top:0px;}
.month {
  font-weight:600;
  text-transform:uppercase;
  padding-top:.5em;
  padding-bottom:.5em;}
.zero .ui[class*="top attached"].segment {border-radius:0px;}
.description {padding-top:.5em;padding-bottom:.5em;}
.ui.corner.label {
  margin-top: 1px;
  margin-left: 1px;
  padding-top: 5px;
  inline-size: 20px;
  border-top-color:#00a99d;
}
.ui.label.floated {
  background-color: transparent;
}
.ui.attached.segment.color {
  outline: 2px solid #0071bc;
  outline-offset: -4px;
}

.ui.dimmer {
  position: relative;
  padding-top: 50px;
}

/* pink */
.label.Yearly {color:white!important;border-top-color:#d4145a!important;}
/* orange */
.label.Monthly {color:white!important;border-top-color:#f15a24!important;}
/* blue */
.label.Weekly {color:white!important;border-top-color:#0071bc!important;}
/* purple */
.label.Daily {color:white!important;border-top-color:#714991!important;}

/* pink */
.card.Yearly {outline: 2px solid #d4145a;outline-offset: -3px;}
/* orange */
.card.Monthly {outline: 2px solid #f15a24;outline-offset: -3px;}
/* blue */
.card.Weekly {outline: 2px solid #0071bc;outline-offset: -3px;}
/* purple */
.card.Daily {outline: 2px solid #714991;outline-offset: -3px;}

.extra.content[data-tooltip] {
  position: static;
}
.circular[data-tooltip]:after {
  z-index: 2;
}

.ui.corner.label{
  background-color: rgba(255, 255, 255, 0) !important;
}
/* 

GENERIC

*/


.login-panel{max-width: 450px;}

.item .description,.item .header	{clear:both; text-align:left;}
.ui.statistic>.value~.label, .ui.statistics .statistic>.value~.label {
    margin-top: 4px;
}
.ui.statistic>.value {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.ui.menu .item img.logo {
    margin-right: 1em;
}

.actions .ui.button {
	padding:.78571429em 0.8em .78571429em;
}

.actions .ui.labeled.icon.button,.actions .ui.labeled.icon.buttons .button {
    position: relative;
    padding-left: 2.5em!important;
    padding-right: 1em!important;
}

.login-form .warning	{
	text-align:left;
}

table.sortable .hidden-text span{
	
	display:none;
}

.responsive-table {overflow-x:auto; display:block;}

.ui.grid .accordion{
	padding-left:0px!important;
	padding-right:0px!important;
}

.dropdown.fluid .ui.input{
	
	padding-right: 1.7em!important;

}
.dropdown.fluid .input>i.icon

{
	right:20px;
}
.sidebar-widget{ background: #ffebd0;}
.ui.widget {

    background: #ffebd0;
    border-radius: 30px;
    margin: 20px 0 25px;
    padding: 20px;
    overflow: hidden;
	    padding: 10px 20px;
	 background: rgb(255,235,208);
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 320px) {
	
	.ui.card.boxed {width:100%;}
	.ui.grid>.column.zero {padding:0px!important;}
	

}

.logo-pad {
    padding-right: 90px
}
