body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 20px;
  border-color: #cfcfd0;
  border-image: none;
  border-style: solid;
  border-width: 1px;
 border-top: 4px solid #1ab394;
}

.actions {
  margin:0px 0 10px 0;
  text-align:right;
}

/*Buttons*/
.btn {
  border-radius: 3px;
  margin-bottom:0px;
  padding: 6px 12px;
}

a.btn {
  text-decoration: none;
  background-color: #dddddd;
  color: #333333;
  border-top: 1px solid #dddddd;
  border-right: 1px solid #898989;
  border-bottom: 1px solid #898989;
  border-left: 1px solid #dddddd;
}

.btn-right{
  float: right;
}

.btn-primary {
  background-color: #1ab394;
  border-color: #1ab394;
  color: #FFFFFF;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.active:focus {
    background-color: #178b74;
    border-color: #178b74;
    color: #FFFFFF;
}

.btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
    background-color: #1fd1ad;
    border-color: #1fd1ad;
}

.btn-outline {
  transition: all .5s;
  background:#1ab394;
  border:1px solid #1ab394;      
}

.btn-outline .fa:before{
  color:#fff;
  font-size:24px;
}

.btn,.inline-button {
 
}


.btn-danger {
  background-color: #ED5565;
  border-color: #ED5565;
  color: #FFFFFF;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger, .btn-danger:active:focus, .btn-danger:active:hover, .btn-danger.active:hover, .btn-danger.active:focus {
    background-color: #ac3f4a;
    border-color: #ac3f4a;
    color: #FFFFFF;
}

.btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
    background-image: none;
}

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger.active[disabled], fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active {
    background-color: #e77d88;
    border-color: #e77d88;
}

.action-btn{
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0px;
  display: inline-block;
  clear: none;
  float: none;
  color:#fff; 
  text-align: center;
  
}

.action-btn > i{
  margin-right: 0px !important;
}

i .action-btn span{
  text-align: center;
  padding-left: 3px;
}

.link-button {
  background:none!important;
  color:blue;
  border:none; 
  padding:0!important;
  font: inherit;
  border-bottom:1px solid blue; 
  cursor: pointer;
}

table td .action-btn{
  margin:2px!important;
}
.IssueSearch{
  padding-bottom: 1em
}
.search-date-delimiter{
  padding-top: 0.7em;
  text-align: center;
  font-weight: bold;
}
.full-width {
  width: 100%;
}

.date-picker{
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  padding: .67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34,36,38,.15);
  color: rgba(0,0,0,.87);
  border-radius: .28571429rem;
  -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
  -webkit-transition: box-shadow .1s ease,border-color .1s ease;
  transition: box-shadow .1s ease,border-color .1s ease;
  box-shadow: none;
}

/*pagination */

.pagination {
  text-align: center;
  display: block;
  margin: 10px auto;
  clear: both;
  padding: 0px;
  list-style-type: none;

}

.pagination li  {display:inline-block;}
.datatables-info {text-align: center;margin-top:10px;}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus
{
  background:#19aa8d;
  color:#fff;
}
.ui.centered.grid{
  margin-top:1em!important;
  margin-bottom:2em!important;
}

.ui.pagination.menu .active.item{
  background-color:#1ab394;
  color: #fff;
}

.form-heading h2 {
    float:left;
    text-decoration: none;
    color: #1ab394;
    font-style: inherit;
    font-weight: 500;
    line-height: 38px;
    margin-top:0px;
}

.form-heading  em {
    font-style:normal; 
    color:#1ab394;
}
.form-heading small{
  color:#1ab394;
  padding-left: 1em;
}   

.form-heading  span.label  {
    display:inline-block;
    float:none; 
    margin-left:5px;
    margin-right:5px;
    font-size:12px;
    padding: 7px 10px;
    top: -4px;
    position: relative;
}

.ui.sortable.table thead th{
  border-left: none !important;
}

.list-body{
  font-size: small;
  min-height: 300px;
  clear: both;
}

.site-list > .IssueSearch{
  margin-left: 2.4em;
}

.clearfix {
  clear: both;
}

/*comments*/
.border-top{
  border-top: solid 1px #cfcfd0;
  padding: 1em;
  margin: 5px;
}
.comment-header-left{
  float: left;
  width: 50%;
  font-weight: bold;
}
.comment-header-right{
  float: right;
  width: 50%;
  text-align: right;
}
.comment-header{
  height: 28px;
  padding: 5px;
}
.comment-content{
  padding-top: 10px;
  white-space: pre-wrap;
}

/*issue categories*/
.category-high{
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: red;
}
.category-medium{
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: orange;
}
.category-low{
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: green;
}

.category-open{
  text-align: center;
  background-color: white;
  border: 1px solid lightgrey;
  text-transform: uppercase;
}

.category-closed{
  text-align: center;
  background-color: lightgrey;
  text-transform: uppercase;
}
.category-onhold{
  text-align: center;
  background-color: gray;
  text-transform: uppercase;
}

.sqr-priority-high{
  text-transform: uppercase;
  text-align: center;
  color: white;
  background-color: red;
}
.sqr-priority-medium{
  text-transform: uppercase;
  text-align: center;
  color: white;
  background-color: orange;
}
.sqr-priority-low{
  text-transform: uppercase;
  text-align: center;
  color: white;
  background-color: green;
}

.categoy-inline > div > div{
  display: inline-block;
  white-space: nowrap;
  padding-left: 2em;
  padding-right: 2em;
}

/*issue details*/
.issue-details-header{
  padding-bottom: 0.5em;
}
.issue-cell{
  padding: 2em !important;
}
.issue-details-devider{
  border-left: solid 1px #cfcfd0
}

/*Logbook*/
.pointer {
  cursor: pointer;
}

.documentBody > div > p{
  margin-top: 0.5em;
  color: white;
}
.documentBody > div{
  padding: 2em;
  height: 600px;
  overflow-y: auto;
}
.documentBody .content{
  background: gray;
}

.document-menu{
  border-right: 1px #dededf solid;
}

.pdf-header{
  margin-bottom: 0.5em;
  text-align: center;
}
.pdf-header p{
  color: white;
}
.pdf-header > p > button {
    margin-left: 0.5em;
}
.pdf-body canvas{
  margin: auto;
}

.site-visit-container{
  padding: 2em;
  background: gray;
}
.site-visit-iframe{
  width: 100%;
  border: none;
  height: 600px;
  overflow-y: auto;
}

.site-visit-header{
  margin-bottom: 1em;
  text-align: center;
}

.site-visit-header p{
  color: white;
}

.site-visit-header > p > a {
  margin-left: 0.5em;
}



/* AK overrides */ 
.btn-outline {
    background: #ffffff!important;
    border: 1px solid #c2c2c5!important;
	font-size:14px;
	}
	
.btn-primary {
    background: #ffffff;
    border: 1px solid #c2c2c5;
    color: #1263ca;
}

.btn {
    border-radius: 3px;
    margin-bottom: 0;
    padding: 9px 10px;
	cursor:pointer;
	font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
	}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active{
	 color: #1263ca;
	 background: #f2f2f2!important;
	/*border-color:#1263ca!important;*/
	border-color:#c2c2c5!important;
}

.closed {
    background: #0dc70d;
    color: #fff;
    border: none;
}
.category {
    padding: 0px 4px;
    display: inline-block;
    min-width: 80px;
	text-align: center;
    text-transform: uppercase;
}

.open {
    background: #e61010;
    color: #fff;
    border: none;
}

.onhold {
    background: #ff6000;
    color: #fff;
    border: none;
}

.float-right{float:right;}
.pusher h1	{float:left;}
.link-button {
    color: #1b1c1d;
    text-decoration: underline;
    border-bottom: 0px;
}

/* borderless datagrid */

.list-body .ui.table{
	border-left:0px;
	border-right:0px;
}

/*IE 11 Fix for Semantic Modal*/
.ui.modal{
  position: relative!important;
  margin: 0px!important;
}